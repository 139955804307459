img {
  max-width: 100%;
}

figure {
  margin: 0;
}

.link {
  color: $orange;
  font-weight: 600;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100%;
}
.ql-editor {
  height: 200px !important;
}

.ml-div {
  @include tabv-m {
    margin-left: auto;
  }
}

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1040;
  padding: 12px 24px;
  box-shadow: 0px 0px 12px -2px rgba($black, 0.1);
  display: flex;

  .logo {
    max-width: 150px;
  }

  .right-icons {
    display: flex;
    align-items: center;

    > div {
      padding: 0 10px;
    }
  }
}

.blankdisable {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  color: #38363a;
  opacity: 0.5;
  font-weight: 600;
  display: inline-block;
  cursor: default;
  outline: none;
  box-sizing: none;
}

input[type='date']:read-only,
input[type='time']:read-only {
  background: rgba(241, 237, 237, 0.4) !important;
  opacity: 0.5;
}
.cursorDefault {
  cursor: default;
}
.cursorPointer{
  cursor: pointer;
}
textarea {
  resize: none;
}
// Input select forms

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='file'],
input[type='date'],
input[type='time'],
textarea,
.MuiInputBase-root .MuiSelect-select,
.MuiInputBase-multiline textarea,
input:-webkit-autofill,
.form-in [class*='control'] {
  width: 100%;
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  // border: 1px solid transparent;
  // border: 1px solid $text;
  color: $black;
  border-radius: 12px;
  padding: 13px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.15;
  min-height: auto !important;
  box-sizing: border-box;
  height: auto;

  &.Mui-disabled,
  &:disabled {
    background: rgba(241, 237, 237, 0.4) !important;
  }

  @include phone-m {
    font-size: 14px;
    padding: 14px 16px;
    line-height: 1.71;
  }

  &::placeholder {
    color: $text !important;
    font-weight: 400;
    opacity: 1 !important;
    visibility: visible !important;
    display: block;
  }

  &:focus {
    outline: none;
    border-color: $orange;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }
}

input {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &[type='date'] {
    position: relative;
    background: white url(../assets/images/icons/calendar-icon.svg) no-repeat;
    background-position: calc(100% - 15px) center;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

select {
  width: 100%;
  background: #ffffff url(../assets/images/icons/caret-down.svg) no-repeat;
  background-position: calc(100% - 20px) center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  color: $black;
  border-radius: 12px;
  padding: 13px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.15;
  min-height: auto !important;
  box-sizing: border-box;
  height: auto;
  height: auto;
  appearance: none;

  &::placeholder {
    color: $text;
  }

  &:focus {
    outline: none;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }

  @include phone-m {
    font-size: 14px;
    padding: 14px 16px;
    line-height: 1.71;
  }
}

.otp-input {
  display: flex;
  justify-content: center;
  padding: 10px 0 4px;

  @include phone-m {
    padding: 20px 0 10px;
  }

  input {
    width: 50px !important;
    height: 50px !important;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    padding: 2px;
    border-radius: 16px;
    border: 1px solid rgba(#888888, 0.17);

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .validation-error {
    text-align: center;
  }
}

.otp-input + .validation-error {
  text-align: center;
}

.validation-error {
  width: 100%;
  margin-top: 7px;
  font-size: 80%;
  color: $red;
  display: block;
}

.space-below {
  .form-in {
    margin-bottom: 16px;
  }
}

.form-in {
  position: relative;
  text-align: left;

  [class*='control'] {
    &:hover {
      border-color: #eaeaea;
    }

    // &:active, &:focus{
    //   border-color: $orange;
    //   outline: none;
    // }

    [class*='ValueContainer'],
    > div {
      margin: 0;
      padding: 0;
      min-height: unset;
      cursor: pointer;

      &:not(:last-child) {
        background: url(../assets/images/icons/caret-down.svg) no-repeat;
        background-position: calc(100% - 5px) center;
      }

      [class*='Input'],
      > div {
        padding: 0;
        margin: 0;
      }
    }

    [class*='menu'] {
      font-size: 14px;
    }
  }

  &.truc {
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 80px;
    }
  }

  > label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    color: $dark;
    margin-bottom: 6px;

    .req {
      color: $orange;
    }
  }

  .react-tel-input {
    input {
      // background: url(../images/icons/caret-down-icon.svg) no-repeat;
      background-position: calc(100% - 10px) center;
      padding-right: 24px;
      width: 120px !important;
    }
  }

  .MuiInputBase-root {
    width: 100%;
  }

  fieldset {
    border: none;
  }

  &.phone {
    z-index: 20;

    .react-tel-input {
      input {
        width: -webkit-fill-available;
      }
    }

    .f-in {
      display: flex;

      > div {
        &:nth-child(2) {
          max-width: 100%;
          padding-right: 10px;
          flex: 1 1 100%;

          input {
            padding-left: 16px;
            border-left: none;
            border-radius: 0px 6px 6px 0;
          }
        }
      }
    }
  }

  .react-tel-input {
    position: relative;
    flex: 0 0;

    .flag-dropdown {
      background-color: transparent;
      border: none;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;

      &.open {
        background-color: transparent;
      }

      .selected-flag {
        opacity: 0;
        width: 100%;
      }
    }

    .form-control,
    input {
      height: auto;
      font-size: 14px;
      padding: 14px 12px;
      line-height: 1.71;
      border: 1px solid #eaeaea;
      border-radius: 6px 0 0 6px;
      border-right: none;
      background: url(../assets/images/icons/down-icon.svg) no-repeat;
      background-position: calc(100% - 10px) center;
      padding-right: 24px;
      max-width: 84px;

      @include phone-m {
        font-size: 14px;
      }
    }

    .country-list {
      .country-name {
        color: $black;
      }
      .country {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  &.grey {
    input,
    .MuiSelect-select,
    .MuiInputBase-multiline textarea {
      background-color: #f1eded;
      background-color: #f8f8f8;
    }
  }

  &.icon {
    .icon-left {
      position: absolute;
      top: 12px;
      left: 8px;
      z-index: 10;

      @include phonev-m {
        left: 12px;
      }

      @include phone-m {
        top: 14px;
      }
    }

    input,
    textarea {
      padding-left: 40px;

      @include phonev-m {
        padding-left: 46px;
      }
    }
  }

  &.right-icon {
    input,
    .MuiInputBase-multiline textarea {
      padding-right: 40px;
    }
  }

  .icon-right {
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 2;
    background: transparent;
    border: none;
    // cursor: pointer;
    transition: all $trans 180ms;

    @include phone-m {
      top: 14px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .btn-right {
    position: absolute;
    right: 10px;
    top: 9px;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all $trans 180ms;

    @include phone-m {
      top: 11px;
    }

    button {
      box-shadow: none;
    }

    .copied {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      background-color: #13af28;
      color: white;
      padding: 4px 10px;
      font-size: 12px;
      border-radius: 4px;
      opacity: 0;
      visibility: hidden;
      font-weight: 500;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .f-in {
    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: $text;
      transition: all cubic-bezier(0.15, 0.66, 0.71, 0.93) 200ms;

      @include phone-m {
        font-size: 16px;
      }
    }
  }

  .error-msg {
    // display: none;
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }

  &.error {
    input,
    select,
    textarea {
      border-color: $red;
    }

    .error-msg {
      display: block;
    }
  }

  &.icon .custom-label:not(.Mui-focused) {
    position: absolute;
    position: absolute;
    background-color: white;
    padding: 0px 4px;
    // font-size: 16px;
    top: -8px;
    z-index: 2;
    left: -4px;
    transform: scale(0.7) !important;
    color: $text;
  }
}

.search-wrapper {
  min-width: 250px;
}

.req {
  color: $orange;
}
.checkbox {
  position: relative;
  display: inline-block;

  .link {
    position: relative;
    z-index: 10;
  }

  label {
    position: relative;
    margin-bottom: 0;
    line-height: 1;
    display: flex;
    // align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: $dark;
    line-height: 1.5;

    &:before {
      content: '';
      width: 14px;
      height: 14px;
      position: relative;
      display: inline-block;
      background: transparent url(../assets/images/icons/check-icon.svg) no-repeat;
      background-position: 15px 0px;
      // background-color: #FFFFFF;
      border: 1px solid #a0a0a0;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 3px;
      flex-shrink: 0;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $black;
      // border-color: $blue;

      &:before {
        background-color: $orange;
        border-color: $orange;
        // box-shadow: 0px 0px 0px 1px rgba($blue, 0.32);
        background-position: center;
      }
    }
  }

  &.f16 {
    label {
      font-size: 16px;

      &:before {
        margin-top: 4px;
      }
    }
  }
}

.radio {
  position: relative;
  display: inline-block;
  padding: 15px 0 16px;

  label {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    color: $dark;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      position: relative;
      display: inline-block;
      // background: url(../images/icons/check-white.svg) no-repeat;
      background-position: 20px;
      background-size: 12px;
      background: #ffffff url(../assets/images/icons/radio.svg) no-repeat;
      background-position: 20px;
      border: 1px solid #c8ccd7;
      border-radius: 50%;
      margin-right: 10px;
      top: 0px;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $dark;

      &:before {
        background-position: center;
        border: 1px solid $orange;
        box-shadow: 0px 0px 0px 1px rgba($blue, 0.12);
      }
    }
  }

  &.sm {
    label {
      font-size: 14px;
    }
  }
}

// Button

@mixin btn(
  $fs: 16px,
  $pd: 10px 40px,
  $fw: 600,
  $bg: $black,
  $color: white,
  $border: $black,
  $hc: white,
  $hbg: $blue,
  $box: 0px 4px 6px rgba(0, 0, 0, 0.2),
  $disabled: false
) {
  font-family: $font;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: $fw;
  line-height: 1.38;
  padding: 10px 18px;
  border-radius: 6px;
  background: $bg;
  color: $color;
  border: 1px solid $border;
  cursor: pointer;
  box-shadow: $box;
  transition: all $trans 240ms;
  // text-transform: uppercase;

  @include phone-m {
    font-size: $fs;
    padding: $pd;
  }

  [class*='icon'] {
    margin-right: 6px;
  }

  &.sm {
    padding: 6px 12px;
    min-width: auto;
    font-size: 12px;
  }

  &.lg {
    padding: 14px 24px;
  }

  // &:focus {
  //   animation: click 290ms ease forwards;
  // }

  &:hover {
    color: $hc;
    border-color: $black;
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
    filter: contrast(1.1) brightness(1.05);
  }

  &:focus,
  &:active {
    outline: none;
    border-color: $black;
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
  }

  @if $disabled {
    background-color: #dadada;
    border-color: #dadada;
    color: #929191;
    box-shadow: none;

    &:hover {
      background-color: #dadada;
      border-color: #dadada;
      color: #929191;
      box-shadow: none;
    }
  }

  &.loading {
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border: 3px solid white;
      border-radius: 50%;
      border-left-color: transparent;
      margin-right: 6px;
      animation: spin 500ms linear infinite forwards;
    }
  }

  &:focus {
    animation: click 290ms ease forwards;
  }
}

@keyframes click {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
}

.btn,
.btn.btn-primary,
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  &:not(.dropdown-toggle) {
    @include btn;
  }
}

.btn.disabled,
.btn:disabled {
  @include btn($disabled: true);
}

.btn-blank {
  @include btn($bg: transparent, $color: $text, $border: transparent, $hbg: $text, $hc: $black);
  box-shadow: none;
}

.btn-white {
  @include btn($bg: white, $color: $black, $hbg: $text, $hc: $black);
  box-shadow: none;
  &.disabled{
    opacity: 0.5;
    cursor: default;
  }
}

.btn-fade {
  @include btn(
    $bg: rgba(255, 255, 255, 0.5),
    $color: white,
    $hbg: transparent,
    $hc: white,
    $border: transparent
  );
  box-shadow: none;

  &:hover {
    border-color: transparent;
  }
}

.MuiSwitch-root.switch-custom {
  padding: 0;
  width: 46px;
  height: auto;

  .Mui-checked + .MuiSwitch-track {
    background: #fff0e3;
    border: 0.5px solid #4d61aa;
  }

  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: $blue;
    }
  }

  .MuiSwitch-track {
    background: #bcbcbc;
    border: 0.5px solid #6f6f6f;
    border-radius: 100px;
    height: 24px;
    flex: 1 1;
  }

  .MuiSwitch-switchBase {
    padding: 0px;
    top: 3px;
    left: 3px;
  }

  .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
  }
}

.btn-grp {
  display: flex;
  align-items: center;
  margin: 0 -8px;

  > div {
    padding: 0 8px;
  }
}

// Dropdown

.dropdown {
  .btn {
    background: transparent;
    border: none;
    color: $black;
    padding: 0;
    box-shadow: none;
    font-size: 18px;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      color: $black;
      background-color: transparent;
    }
  }
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(31, 0, 82, 0.19);
  border-radius: 16px;
  padding: 20px 0px;
  border: none;
  min-width: 160px;

  a {
    font-weight: 700;
    font-size: 15px;
    padding: 10px 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    transition: all 200ms ease;
    color: $dark;

    .icon {
      padding-right: 6px;
    }

    &:hover {
      background-color: whitesmoke;
    }
  }
}

// Modal

.modal {
  &.show {
    // display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.modal-dialog {
  flex: 1 1 100%;
  max-width: 320px;

  @include phone {
    margin: 32px auto 0;
  }

  @include phone-m {
    max-width: 430px;
  }

  .modal-content {
    box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
    border-radius: 20px;
  }

  .modal-header {
    border-bottom: none;
    padding: 36px 24px 26px;

    @include phone-m {
      padding: 36px 40px 26px;
    }
  }

  .modal-body {
    padding: 0px 24px 34px;

    &:first-child {
      padding-top: 34px;
    }

    @include phone-m {
      padding: 0px 40px 34px;
    }
  }

  .modal-footer {
    flex-direction: column;
    border-top: none;
    padding: 0 24px 36px;

    @include phone-m {
      padding: 0 40px 36px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      width: 100%;
      margin: 0;

      button,
      a {
        flex: 1 1;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.back-modal {
          flex: 0 1 auto;
        }
      }
    }
  }

  .btn-close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -10px;
    right: -10px;
    background: white url(../assets/images/icons/close-icon.svg) no-repeat center;
    border-radius: 100px;
    opacity: 1;
    border: 2px solid white;
    padding: 0;
  }
}

// Table

.table-sec {
  min-height: 700px;
}
.table-sec-2 {
  min-height: 300px;
}

table {
  width: 100%;
  min-width: 800px;

  &.user {
    tr {
      td,
      th {
        &:nth-child(2),
        &:first-child {
          text-align: left;
        }
      }
    }
  }

  th {
    padding: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    border-top: 1px solid #d6dbde;
    border-bottom: 1px solid #d6dbde;
  }

  td {
    padding: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
  }

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid #d6dbde;
      }
    }
  }
}

.table-btns {
  display: inline-flex;
  align-items: center;

  button {
    display: inline-flex;
    justify-content: center;
    padding: 5px 24px;
    background: rgba(138, 138, 138, 0.1);
    border: 1px solid rgba(138, 138, 138, 0.1);
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  a {
    display: inline-flex;
    justify-content: center;
    padding: 5px 24px;
    background: rgba(138, 138, 138, 0.1);
    border: 1px solid rgba(138, 138, 138, 0.1);
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.table-sec {
  position: relative;
  background-color: white;
  overflow-x: auto;

  .heading {
    padding: 24px 24px 0;
  }
}

.table-sec-2 {
  position: relative;
  background-color: white;
  overflow-x: auto;

  .heading {
    padding: 24px 24px 0;
  }
}

.dp {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    padding-left: 10px;
  }

  figure {
    width: 38px;
    height: 38px;

    img {
      max-width: 100%;
      border-radius: 100%;
    }
  }
}

.thumb {
  position: relative;
  width: 80px;
  height: 43px;
  margin: 0 auto;

  img {
    @include img($ob: cover);
  }
}

.label {
  display: inline-block;
  padding: 5px 8px;
  border-radius: 60px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.3px;
  background-color: whitesmoke;
  min-width: 90px;
  text-align: center;

  &.green {
    background-color: rgba(1, 188, 130, 0.1);
    color: $green;
  }

  &.red {
    background-color: rgba(226, 0, 0, 0.1);
    color: $red;
  }
}

// Common

.main {
  position: relative;
  display: flex;
  min-height: 100vh;

  aside {
    position: relative;
    flex: 0 0 260px;

    >div{
       position: sticky;
      top: 70px;
    }
  }
}

aside {
  background-color: whitesmoke;
  padding: 62px 0 24px;

  ul {
    li {
      margin-bottom: 0;
      a {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 400;
        color: $black;

        &.active {
          background-color: gainsboro;
          font-weight: 500;
        }
      }
    }
  }

  .accordion {
    background-color: transparent;
    .accordion-item {
      background-color: transparent;
      border: none;

      .accordion-body {
        padding: 0;

        ul {
          margin-bottom: 12px;
          li {
            a {
              padding: 8px 50px;
              font-size: 14px;
              font-weight: 500;

              &.active {
                color: $blue;
                background-color: white;
              }
            }
          }
        }
      }
    }
    .accordion-header {
      button {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 400;
        color: #2b2e36;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.right-side {
  position: relative;
  flex: 1 1 100%;
  max-width: calc(100% - 260px);
  padding: 90px 0;
  background-color: #f9f9f9;
}

.heading {
  display: flex;
  margin-bottom: 24px;

  &:not(.table-heading) {
    flex-direction: column;
    @include phone-m {
      flex-direction: row;
    }
  }

  @include tabv-m {
    flex-direction: row;
    align-items: center;
  }

  @include tabv {
    margin-bottom: 32px;
  }

  h2,
  h5 {
    margin-bottom: 0;
  }
}

.dropdown .filter-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  padding: 12px;
}

.dashboard-cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  > div {
    display: flex;
    padding: 0 15px 24px;
    flex: 0 0 33.333%;
    max-width: 33.333%;

    > div {
      flex: 1 1 100%;
      background-color: white;
      border: 1px solid rgb(232, 232, 232);
      padding: 0 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 24px;
    }
  }

  figure {
    flex-shrink: 0;
  }

  .text {
    padding: 0 12px;
    word-break: break-word;

    h5 {
      margin-bottom: 4px;
    }
  }
  .ml-auto {
    align-self: flex-start;
  }
}

// User

.user-sec {
  position: relative;
  background-color: white;
  border: 1px solid #cfcfcf;
  border-radius: 6px;

  > .d-flex {
    align-items: center;
    padding: 15px 24px;
    border-bottom: 1px solid #cfcfcf;

    h6 {
      margin-bottom: 0;
    }
  }
}

.user-dp {
  position: relative;
  max-width: 200px;

  figure {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
    }
  }

  .blank-btn {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}
.profile-in {
  padding: 48px;

  .d-flex {
    align-items: center;

    > div {
      &:not(:first-child) {
        padding: 0 48px;
      }
    }
  }
}

.change-password {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(172, 172, 172, 0.25);
  border-radius: 6px;
  padding: 30px;
}

.filter-drop {
  .dropdown-menu {
    min-width: 265px;
  }

  .heading {
    padding: 6px 24px 0;
    margin-bottom: 16px;
  }

  .accordion {
    .accordion-item {
      border: none;
      border-radius: 0;
      border-top: 1px solid #c1c1c1;
    }

    .accordion-body {
      background-color: whitesmoke;
    }

    .radio {
      padding: 0;
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        &:before {
          margin-right: 0;
        }
      }
    }

    .form-in {
      input {
        padding: 10px 10px;
        border-radius: 6px;
      }
    }
  }

  .accordion-header {
    button {
      background-color: white;
      color: $black;
      font-weight: 500;
      font-size: 15px;
      outline: none !important;
      box-shadow: none;

      &:after {
        width: 16px;
        height: 16px;
        background-size: contain;
      }

      i {
        margin-right: 12px;
      }
    }
  }
}

// User

.user-left {
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  .dp-img {
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;

    figure {
      position: relative;
      width: 200px;
      aspect-ratio: 1/1;
      margin: 0 auto 12px;

      img {
        @include img($ob: cover);
        border-radius: 50%;
      }
    }
  }

  .row {
    div {
      margin-bottom: 16px;
      p {
        word-break: break-word;

        &:not(.text-text) {
          font-size: 16px;
          margin-bottom: 4px;
          font-weight: 500;
        }
      }
    }
  }
}

.user-right {
  background-color: white;
  padding: 24px;
  border-radius: 8px;

  .row {
    div {
      margin-bottom: 16px;
      p {
        word-break: break-word;

        &:not(.text-text) {
          font-size: 16px;
          margin-bottom: 4px;
          font-weight: 500;
        }
      }
    }
  }
}

.social-links {
  a {
    display: block;
    margin-bottom: 12px;
    font-size: 14px;

    i {
      margin-right: 6px;
    }
  }
}

.collectives-collapse {
  padding-top: 24px;
  border-top: 1px solid #e8e8e8;

  .accordion-body {
    overflow: auto;
    max-height: 300px;
    @include no-scrollbar;
  }

  table {
    min-width: auto;

    tbody{
      max-height: 300px;
      overflow: auto;

      @include no-scrollbar;
    }
  }
}

// Pagination

.pagination-div {
  padding: 20px 0;

  .pagination-wrapper {
    justify-content: space-between;
    padding: 0 15px;
  }
}

.pagination {
  align-items: center;

  li {
    margin: 0 5px;
    button {
      border-radius: 4px;

      &.pagination-link {
        background-color: transparent;
        border: none;
      }
    }
  }
}

.per-page {
  align-items: center;
  .number-box {
    margin-right: 20px;

    .react-select {
      [class*='menu'] {
        min-width: 80px;
      }
    }
  }

  .values {
    display: flex;

    span {
      padding-right: 6px;
    }
  }
}

.right-in {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(172, 172, 172, 0.25);
  border-radius: 6px;
  padding: 30px;
}

.loader {
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    border: 2px solid $black;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation: spin 600ms linear forwards infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
// Text-Editor
.public-DraftStyleDefault-block {
  margin: 0px !important;
}
.rdw-editor-main {
  height: 400px !important;
}
.wrapperClassName {
  // height: 400px;
  background-color: white;
  border: 1px solid #eaeaea;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.tab-table {
  .nav-tabs {
    padding: 20px 5px 10px;
    border: none;
    margin-bottom: 0;

    li {
      margin-bottom: 0;
      margin: 0 10px;

      button {
        border: none;
        font-weight: 600;
        border-radius: 6px;
        font-size: 14px;
        padding: 12px 24px;

        &:not(.active) {
          background: #d9d9d9;
          border-radius: 5px;
          color: #676767;
        }

        &.active {
          background-color: $black;
          color: white;
        }
      }
    }
  }
}

.text-box{
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #d3d3d3;

  h1,h2,h3,h4,h5,h6{
    padding-top: 10px;
  }

  ul{
    list-style-type: disc;

    li{
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.3;
      color: $black;
    }
  }

  a{
    color: $blue !important;
    text-decoration: underline !important;
    font-weight: 500;
  }

}
.break-word{
  word-break: break-all;
}

.banners-img{
  position: relative;
  max-height: 210px;
  overflow: auto;
  @include no-scrollbar;
}

.delete-img{
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  border: 1px solid white;
  backdrop-filter: blur(12px);
  color: white;
  background-color: rgba(0, 0, 0, 0.487);
}

.pic-ulpload-icon{
  position: absolute;
  // bottom: -10px;
  // right: -10px;
  width: 50px;
  height: 50px;
  background: url(../assets/images/pic-upload.svg) no-repeat center;
  background-size: contain;
  border: none;
&.disabled{
  opacity: 0.5;
}
}

.gift-modal{
  .modal-header, .modal-body{
    max-width: 300px;
    margin: 0 auto;
  }
}

.loader-in {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  background-color: rgb(255 255 255 / 90%);

  // min-height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid $orange;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spinner 1000ms ease-in-out infinite alternate forwards;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1200deg);
  }
}

.word_break{
  word-break: break-all;
}